import { useCallback, useRef, useState } from "react";
import { Button, Menu, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { User } from "@auth0/auth0-react";
import UserAvatar from "./UserAvatar/UserAvatar";

const useStyles = makeStyles({
  userContainer: {
    position: "absolute",
    zIndex: 20,
    top: 0,
    right: 0,
    margin: "1em",
    display: "flex",
    alignItems: "center"
  },
  userButton: {
    color: "black"
  },
  logoutLink: {
    color: "white",
    cursor: "pointer",
    padding: "10px 20px"
  }
});

interface UserMenuProps {
  user: User;
  signOut?: () => void;
}

const UserMenu = ({ user, signOut }: UserMenuProps) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleSignOut = useCallback(() => {
    signOut?.();
  }, [signOut]);

  // if (process.env.REACT_APP_SET_AUTH === "passcode") {
  //   return (
  //     <div className={classes.userContainer}>
  //       <Link onClick={handleSignOut} className={classes.logoutLink}>
  //         Logout
  //       </Link>
  //     </div>
  //   );
  // }

  return (
    <div className={classes.userContainer}>
      <UserAvatar user={user} />
      <Button onClick={() => setMenuOpen((isOpen) => !isOpen)} ref={anchorRef} className={classes.userButton}>
        {user.nickname}
        {/* <ExpandMoreIcon /> */}
      </Button>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <MenuItem onClick={handleSignOut}>
          <Typography variant="body1">Logout</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
